import React, { Fragment } from 'react';
import { StarIcon, ArrowLeftIcon } from '@heroicons/react/20/solid';
import { Tab } from '@headlessui/react';
import { Link } from 'react-router-dom';

const product = {
  name: 'Stripe Cart Checkout App',
  version: 'v1.0 released on August 01, 2024',
  originalPrice: '399',
  salePrice: '199',
  description: 'The Stripe Cart Checkout App provides a seamless and secure payment processing system for your e-commerce website.',
  highlights: [
    'Easy integration with Stripe',
    'Customizable shopping cart',
    'Customizable Backend',
    'Responsive design',
  ],
  rating: 4.5,
  license: 'For personal and commercial use'
};

const reviews = [
  // Add some sample reviews here
];

const faqs = [
  // Add some sample FAQs here
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function VideoGallery() {
  return (
    <div className="lg:col-span-7 h-full">
      <div className="aspect-w-16 aspect-h-9 h-full overflow-hidden rounded-lg">
        <iframe
          src="https://www.youtube.com/embed/0qDHQWLrrT4"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="w-full h-full object-cover"
        ></iframe>
      </div>
    </div>
  );
}

function ProductInfo() {
  return (
    <div className="lg:col-span-5">
      <div className="flex flex-col h-full justify-between">
        <div>
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">{product.name}</h1>
          <p className="mt-1 text-sm text-gray-500">{product.version}</p>
          
          <div className="mt-4 flex items-center">
            {[0, 1, 2, 3, 4].map((rating) => (
              <StarIcon
                key={rating}
                className={classNames(
                  product.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                  'h-5 w-5 flex-shrink-0'
                )}
                aria-hidden="true"
              />
            ))}
          </div>

          <p className="mt-6 text-gray-700">{product.description}</p>

          <div className="mt-8 space-y-4">
            <h3 className="text-sm font-medium text-gray-900">Highlights</h3>
            <ul className="list-disc pl-5 space-y-2">
              {product.highlights.map((highlight, index) => (
                <li key={index} className="text-sm text-gray-500">{highlight}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-10 flex items-center justify-between">
          <div>
            <span className="text-2xl font-bold text-gray-900">${product.salePrice}</span>
            <span className="ml-2 text-lg text-gray-500 line-through">${product.originalPrice}</span>
          </div>
          <div className="space-x-4">
            <button
              type="button"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Contact Us
            </button>
            {/* <button
              type="button" 
              className="inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Preview
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProductTabs() {
  return (
    <div className="mt-16 w-full max-w-2xl lg:col-span-12 lg:mt-0 lg:max-w-none">
      <Tab.Group as="div">
        <div className="border-b border-gray-200">
          <Tab.List className="-mb-px flex space-x-8">
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300',
                  'whitespace-nowrap py-6 border-b-2 font-medium text-sm'
                )
              }
            >
              Customer Reviews
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300',
                  'whitespace-nowrap py-6 border-b-2 font-medium text-sm'
                )
              }
            >
              FAQ
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300',
                  'whitespace-nowrap py-6 border-b-2 font-medium text-sm'
                )
              }
            >
              License
            </Tab>
          </Tab.List>
        </div>
        <Tab.Panels as={Fragment}>
          <Tab.Panel className="pt-10">
            {/* Customer Reviews content */}
          </Tab.Panel>
          <Tab.Panel className="pt-10">
            {/* FAQ content */}
          </Tab.Panel>
          <Tab.Panel className="pt-10">
            {/* License content */}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default function PD1() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="absolute top-4 right-4">
          <Link to="/products" className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <ArrowLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            Back to Products
          </Link>
        </div>
        <div className="lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-8">
          <VideoGallery />
          <ProductInfo />
        </div>
        {/* <ProductTabs /> */}
      </div>
    </div>
  )
}
