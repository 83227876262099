import logo from './logo.svg';
import './App.css';
import Landing from './pages/Landing/index';
import Features from './pages/Features/index';
import Pricing from './pages/Pricing/index';
import ContactUs from './pages/ContactUs/index';
import Terms from './pages/Landing/Terms';
import About from './pages/Landing/About';
import Products from './pages/Products/index';
import PD1 from './pages/Products/Details/PD1';
import PD2 from './pages/Products/Details/PD2';
import PD3 from './pages/Products/Details/PD3';
import PD4 from './pages/Products/Details/PD4';

import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/pd1" element={<PD1 />} />
          <Route path="/products/pd2" element={<PD2 />} />
          <Route path="/products/pd3" element={<PD3 />} />
          <Route path="/products/pd4" element={<PD4 />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
