import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronRightIcon, ArrowLeftIcon } from '@heroicons/react/20/solid';

const products = [
  {
    id: 1,
    name: 'Startup Launch Kit',
    description: 'Essential tools for launching your startup',
    originalPrice: '$399',
    salePrice: '$199',
    image: 'product-images/pd3.png',
    href: '/products/pd1'
  },
  {
    id: 2,
    name: 'User Signup/Login App',
    description: 'Secure User Authentication System',
    originalPrice: '$499',
    salePrice: '$299',
    image: 'product-images/pd3.png',
    href: '/products/pd2'
  },
  {
    id: 3,
    name: 'Stripe Cart Checkout Payment App',
    description: 'Seamless e-commerce payment solution',
    originalPrice: '$399',
    salePrice: '$199',
    image: 'product-images/pd3.png',
    href: '/products/pd3'
  },
  {
    id: 4,
    name: 'Stripe Subscription Payment App',
    description: 'Recurring payment system for SaaS',
    originalPrice: '$499',
    salePrice: '$249',
    image: 'product-images/pd3.png',
    href: '/products/pd4'
  }
];

const ProductCard = ({ product }) => (
  <div className="group relative bg-white p-4 sm:p-6 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
    <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75 relative">
      <img src={product.image} alt={product.name} className="h-full w-full object-cover object-center" />
      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 flex items-center justify-center transition-all duration-300">
        <Link to={product.href} className="px-4 py-2 bg-white text-gray-900 font-semibold rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          View Details
        </Link>
      </div>
    </div>
    <div className="pt-4 text-center">
      <h3 className="text-sm font-medium text-gray-900">
        <Link to={product.href}>
          <span aria-hidden="true" className="absolute inset-0" />
          {product.name}
        </Link>
      </h3>
      <p className="mt-1 text-sm text-gray-500">{product.description}</p>
      <div className="mt-2 flex justify-center items-center">
        <p className="text-sm line-through text-gray-500 mr-2">{product.originalPrice}</p>
        <p className="text-base font-semibold text-gray-900">{product.salePrice}</p>
      </div>
    </div>
  </div>
);

const ProductsPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gradient-to-b from-purple-100 to-white min-h-screen">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-16 sm:py-24 relative">
        <button
          onClick={() => navigate('/')}
          className="absolute top-4 right-4 p-2 rounded-full bg-black shadow-md hover:bg-gray-100 transition-colors duration-200"
        >
          <ArrowLeftIcon className="h-6 w-6 text-white" />
        </button>
        <div className="text-center mb-12">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            Our Products
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Discover our range of  products designed to help you launch your startup
          </p>
        </div>

        <div className="mt-10 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {products.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-xl font-semibold text-gray-700">
            More products coming soon!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
