import React from 'react';
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, RocketLaunchIcon,
  GlobeAltIcon, AtSymbolIcon, KeyIcon, ArrowLeftIcon
 } from '@heroicons/react/24/outline'
 import { useNavigate } from 'react-router-dom'
export default function ContactUs() {
  const navigate = useNavigate()
  return (
    <div className="bg-white">
      <button
        onClick={() => navigate(-1)}
        className="absolute top-4 right-4 p-2 bg-white rounded-full text-balck hover:bg-white-700 transition-colors"
        aria-label="Go back"
      >
        <ArrowLeftIcon className="h-6 w-6" />
      </button>
        <div className="">
          <iframe
            aria-label="Contact Us"
            frameBorder="0"
            style={{ height: '1000px', width: '100%', border: 'none' }}
            src="https://forms.zohopublic.eu/zerostartup/form/ContactUs/formperma/zaFkuAlLLk6otDHuNDP-api8QQ0Q-R6xMWlR1OjOlhI"
          ></iframe>
        </div>
      </div>
  );
}