import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const Terms = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24 relative">
      <button
        onClick={() => navigate(-1)}
        className="absolute top-4 right-4 p-2 bg-indigo-600 rounded-full text-white hover:bg-indigo-700 transition-colors"
        aria-label="Go back"
      >
        <ArrowLeftIcon className="h-6 w-6" />
      </button>
      <div className="relative max-w-xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Terms and Conditions
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Please read these terms and conditions carefully before using Zero2OneLaunch's services.
          </p>
        </div>
        <div className="mt-12">
          <h3 className="text-lg font-medium text-gray-900">1. Acceptance of Terms</h3>
          <p className="mt-2 text-base text-gray-500">
            By using Zero2OneLaunch's services, you agree to be bound by these Terms and Conditions.
          </p>

          <h3 className="mt-8 text-lg font-medium text-gray-900">2. Services</h3>
          <p className="mt-2 text-base text-gray-500">
            Zero2OneLaunch provides various services to help launch and grow startups. These services are subject to change and availability.
          </p>

          <h3 className="mt-8 text-lg font-medium text-gray-900">3. User Responsibilities</h3>
          <p className="mt-2 text-base text-gray-500">
            Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.
          </p>

          <h3 className="mt-8 text-lg font-medium text-gray-900">4. Intellectual Property</h3>
          <p className="mt-2 text-base text-gray-500">
            All content and materials available through Zero2OneLaunch's services are the property of Zero2OneLaunch or its licensors and are protected by copyright, trademark, and other intellectual property laws.
          </p>

          <h3 className="mt-8 text-lg font-medium text-gray-900">5. Limitation of Liability</h3>
          <p className="mt-2 text-base text-gray-500">
            Zero2OneLaunch shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues.
          </p>

          <h3 className="mt-8 text-lg font-medium text-gray-900">6. Modifications to Terms</h3>
          <p className="mt-2 text-base text-gray-500">
            Zero2OneLaunch reserves the right to modify these Terms and Conditions at any time. Continued use of the services after any such changes shall constitute your consent to such changes.
          </p>

          <h3 className="mt-8 text-lg font-medium text-gray-900">7. Governing Law</h3>
          <p className="mt-2 text-base text-gray-500">
            These Terms and Conditions are governed by and construed in accordance with the laws of the jurisdiction in which Zero2OneLaunch operates.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
