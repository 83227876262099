import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, RocketLaunchIcon,
  GlobeAltIcon, AtSymbolIcon, KeyIcon, ArrowLeftIcon
 } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

const features = [
  {
    name: 'Domain Registration',
    description:
      "Secure your online identity with ease. We simplify domain registration and setup, ensuring your app has a professional web address from the start.",
    icon: GlobeAltIcon,
  },
  {
    name: 'Email',
    description:
      "Look credible from day one with custom email addresses. Set up professional email for your team, with the first 5 users completely free.",
    icon: AtSymbolIcon,
  },
  {
    name: 'Landing Zone',
    description:
      "Your app's perfect launchpad. We prepare a tailored infrastructure environment, ensuring your deployment has the ideal foundation for performance, security, and scalability from day one.",
    icon: RocketLaunchIcon,
  },
  {
    name: 'One-Click Deployment',
    description:
      'Go from code to live in seconds. Our one-click deployment gets your app up and running instantly, letting you focus on what matters - building your product.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Advanced security',
    description:
      'Enterprise-level protection for startups. Get SSL Certificates, Data Encryption, DDoS mitigation, firewall rules, and intrusion detection without the enterprise price tag.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Single Sign-On (SSO)',
    description:
      'Streamline authentication with SSO. Enable centralized identity management for your team, enhancing security and productivity as your startup grows.',
    icon: KeyIcon,
  }  
]

export default function Example() {
  const navigate = useNavigate()

  return (
    <div className="bg-white py-24 sm:py-32 relative">
      <button
        onClick={() => navigate(-1)}
        className="absolute top-4 right-4 p-2 bg-indigo-600 rounded-full text-white hover:bg-indigo-700 transition-colors"
        aria-label="Go back"
      >
        <ArrowLeftIcon className="h-6 w-6" />
      </button>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">Launch faster</h2> */}
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Launch Your Startup Faster
          </p>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Everything You Need to Deploy Your App
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Get your brilliant idea to market in record time. Our streamlined deployment solutions are built for agile startups and rapid growth.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}