import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const About = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24 relative">
      <button
        onClick={() => navigate(-1)}
        className="absolute top-4 right-4 p-2 bg-indigo-600 rounded-full text-white hover:bg-indigo-700 transition-colors"
        aria-label="Go back"
      >
        <ArrowLeftIcon className="h-6 w-6" />
      </button>
      <div className="relative max-w-xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            About Zero2OneLaunch
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Empowering innovators to launch their dreams without financial barriers.
          </p>
        </div>
        <div className="mt-12">
          <h3 className="text-xl font-semibold text-gray-900">Our Mission</h3>
          <p className="mt-2 text-base text-gray-500">
            At Zero2OneLaunch, we're on a mission to democratize the startup ecosystem. We believe that great ideas shouldn't be held back by financial constraints. Our goal is to provide aspiring entrepreneurs with the tools, resources, and support they need to launch their startups with zero monthly running costs.
          </p>

          <h3 className="mt-8 text-xl font-semibold text-gray-900">Our Values</h3>
          <ul className="mt-2 text-base text-gray-500 list-disc list-inside">
            <li>Innovation: We constantly push the boundaries to provide cutting-edge solutions.</li>
            <li>Accessibility: We make startup resources available to everyone, regardless of their financial background.</li>
            <li>Empowerment: We believe in equipping entrepreneurs with the knowledge and tools to succeed.</li>
            <li>Community: We foster a supportive ecosystem where startups can grow and thrive together.</li>
          </ul>

          <h3 className="mt-8 text-xl font-semibold text-gray-900">What We Offer</h3>
          <p className="mt-2 text-base text-gray-500">
            Zero2OneLaunch provides a comprehensive suite of services designed to get your startup off the ground quickly and efficiently. From domain registration and email setup to one-click deployments and advanced security features, we've got you covered. Our platform is built to scale with your business, ensuring you have the support you need at every stage of your journey.
          </p>

          <h3 className="mt-8 text-xl font-semibold text-gray-900">Join Us</h3>
          <p className="mt-2 text-base text-gray-500">
            Whether you're a first-time entrepreneur or a seasoned startup veteran, Zero2OneLaunch is here to support your vision. Join our community of innovators and let's build the future together – starting at zero.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
